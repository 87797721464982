import React,{useState,useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import './user.css'


const User = () => {

    const params =useParams()
    var id = params.id
const[user,setUser]=useState([])
const[load,setLoad]=useState(false)
const[error,setError]=useState(null)

useEffect(() => {
    axios.get("https://jsonplaceholder.typicode.com/posts/"+id).
    then((response)=>{
        setLoad(true)
        setUser(response.data)
    }).catch((error)=>{
        setLoad(true)
        setError(error)
    })
}, []);

if (error){
    return <div>Error : {error.message}</div>
}else if(!load){
return <div>Loading ...</div>
}else{

  return (
    <div className='user-container'>
        <div className='user-title-body'>
            <h1 className='name'>{user.title}</h1>
      <h1 className='user-hr' />
       <p className='email'> {user.body}</p>    
        </div>
        <Link to={`/user/${id}/comments`}>
              <p>Comments</p>
        </Link>
     
    </div>
  )
}
}

export default User