import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import './comment.css'

const Comment = () => {

    const params =useParams()
    var id = params.id

const[commnet,setComment]=useState([])
const[error,setError]=useState(null)
const[load,setLoad]=useState(false)

useEffect(() => {
   
    axios.get (`https://jsonplaceholder.typicode.com/posts/${id}/comments`).
    then((response)=>{
        setLoad(true)
        setComment(response.data)
    }).catch((error)=>{
        setLoad(true)
        setError(error)
    })
}, []);

if(error){
    return<div>Error : {error.message}</div>
}else if(!load){
    return <div>Loading ...</div>
}else{
     return (
    <div>
        <h1>Comments</h1>
     {commnet.map((cm)=>{
        return(
            <div className='comment-full'>
                <p className='cm-name'>{cm.name}</p>
                <p className='cm-email'>{cm.email}</p>
                <h1 className='cmmnt-hr'/>
                <p className='cm-body'>{cm.body}</p>
            </div>
        )
     })}
    </div>
  )
}
 
}

export default Comment