import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import './home.css'

const Home = () => {

const[post,setPost]=useState([])
const[load,setLoad]=useState(false)
const[error,setError]=useState(null)

useEffect(() => {
    axios.get("https://jsonplaceholder.typicode.com/posts").
    then((response)=>{
        setLoad(true)
        setPost(response.data)}).
    catch((error)=>{
        setLoad(true)
        setError(error)
    })
}, []);


if(error){
    return <div>Error : {error.message}</div>
}else if(!load){
    return <div>Loading ...</div>
}else{
  return (
    <div className='cart-container'>
        
        {post.map((p)=>{
           
            return(
                <Link className='user-link' to={`/user/${p.id}`}>
                <div className='cart-body' key={p.id}>
                    <p className='cart-title'>{p.title}</p>
                    {/* <hr className='cart-hr'/>
                    <p className='cart-text'> {p.username}</p> */}
                </div>

                </Link>
            )
        })}
      
    </div>
  )
    }
}

export default Home

// import React, { useState, useEffect }  from 'react';
// const Home = () => {
// const [error, setError] = useState(null);
//     const [isLoaded, setIsLoaded] = useState(false);
//     const [users, setUsers] = useState([]);
//     useEffect(() => {
//         fetch("https://jsonplaceholder.typicode.com/users/")
//             .then(res => res.json())
//             .then(
//                 (data) => {
//                     setIsLoaded(true);
//                     setUsers(data);
//                 },
//                 (error) => {
//                     setIsLoaded(true);
//                     setError(error);
//                 }
//             )
//       }, [])
// if (error) {
//         return <div>Error: {error.message}</div>;
//     } else if (!isLoaded) {
//         return <div>Loading...</div>;
//     } else {
//         return (
//             <ul>
//                 {users.map(user => (
//                 <li key={user.id}>
//                     {user.name} 
//                 </li>
//                 ))}
//             </ul>
//         );
//     }
// }
// export default Home;


